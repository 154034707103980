.mq-typo-text {
  color: currentColor;
  max-width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1em;

  &.uppercase {
    text-transform: uppercase;
  }

  &.neutral {
    color: var(--color-neutral-text);
  }

  &.indigo {
    color: var(--color-brand-indigo);
  }

  &.neutral-light {
    color: var(--color-neutral-text-light);
  }

  &.neutral-med {
    color: var(--color-neutral-text-med);
  }

  &.purple {
    color: var(--color-marketing-purple);
  }

  &.purple-med {
    color: var(--color-marketing-purple-med);
  }

  &.blue {
    color: var(--color-brand-blue);
  }

  &.nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.mixed {
    * {
      display: contents;
      max-width: 100%;
    }
  }

  &.center {
    text-align: center;
  }

  &.bold {
    font-weight: 700;
  }

  &.italic {
    font-style: italic;
  }

  &.underline {
    text-decoration: underline;
  }

  &.dashed {
    text-decoration-style: dashed;
  }

  &.dotted {
    text-decoration-style: dotted;
  }

  &.solid {
    text-decoration-style: solid;
  }

  &.initial {
    text-decoration-style: initial;
  }

  &.mq-typo-text-size-auto {
    font-size: 1em;
  }

  &.mq-typo-text-size-1x {
    font-size: var(--mq-font-size-scale-1);
  }

  &.mq-typo-text-size-2x {
    font-size: var(--mq-font-size-scale-2);
  }

  &.mq-typo-text-size-3x {
    font-size: var(--mq-font-size-scale-3);
  }

  &.mq-typo-text-size-4x {
    font-size: var(--mq-font-size-scale-4);
  }

  &.mq-typo-text-size-5x {
    font-size: var(--mq-font-size-scale-5);
  }

  &.mq-typo-text-size-6x {
    font-size: var(--mq-font-size-scale-6);
  }

  &.mq-typo-text-size-7x {
    font-size: var(--mq-font-size-scale-7);
  }

  &.mq-typo-text-size-8x {
    font-size: var(--mq-font-size-scale-8);
  }

  &.mq-typo-text-size-9x {
    font-size: var(--mq-font-size-scale-9);
  }

  &.mq-typo-text-size-10x {
    font-size: var(--mq-font-size-scale-10);
  }
}