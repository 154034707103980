.mq-button {
  max-width: 100%;
  width: fit-content;
  height: max-content;
  min-height: 2.4rem;
  max-height: 2.5rem;
  display: inline-flex;
  align-items: center;
  border-radius: var(--mq-border-radius-sm);
  border: 1px solid transparent;
  text-align: center;
  justify-content: center;
  padding: var(--gl-spacing-scale-3) var(--gl-spacing-scale-5);
  background-color: transparent;
  font-weight: 700;
  line-height: var(--mq-line-height-sm);
  position: relative;
  transition: all ease-in-out .3s;
  cursor: pointer;
  gap: var(--gl-spacing-scale-3);

  &:not(:has(.mq-button__meta)) {
    .mq-button__child {
      padding-right: var(--gl-spacing-scale-4);
      padding-left: var(--gl-spacing-scale-4);
    }
  }

  &:has(.mq-button__start-icon ) {
    .mq-button__loading {
      position: absolute;
      left: var(--gl-spacing-scale-5);
    }
  }

  &:has(.mq-button__end-icon ) {
    .mq-button__loading {
      position: absolute;
      right: var(--gl-spacing-scale-5);
    }
  }

  &:not(:has(.mq-button__child)) {
    .mq-button__loading {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.full-width {
    width: 100%;
  }

  &.round {
    border-radius: var(--mq-border-radius-sm);
  }

  &.square {
    min-height: 2.25rem;
    min-width: 2.25rem;
    aspect-ratio: 1 / 1;
    border-radius: var(--mq-border-radius-sm);
    flex-shrink: 0;

    &:has(.mq-button__loading ) {
      .mq-button__start-icon,
      .mq-button__end-icon,
      .mq-button__secondary-icon,
      .mq-button__primary-icon {
        display: none;
      }
    }
  }

  &.circle {
    padding: var(--gl-spacing-scale-3);
    min-height: auto;
    max-height: fit-content;
    min-width: auto;
    aspect-ratio: 1 / 1;
    border-radius: var(--mq-border-radius-circle);

    &.sm {
      height: 34px;
      width: 34px;
    }

    .mq-button__child {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.sm {
    font-size: var(--mq-font-size-scale-2);
  }

  &.md {
    font-size: var(--mq-font-size-scale-3);
  }

  &.lg {
    font-size: var(--mq-font-size-scale-5);
  }

  &__secondary-icon {
    display: none;
  }

  &:hover,
  &:focus,
  &:active {
    &:not(:disabled),
    &:not(.disabled) {
      box-shadow: var(--mq-shadow-sm);
      outline: none;

      .mq-button__secondary-icon {
        display: initial;
      }

      .mq-button__primary-icon {
        display: none;
      }
    }
  }

  &.primary {
    &:not(:disabled),
    &:not(.disabled) {
      background-color: var(--color-brand-blue);
      border-color: var(--color-brand-blue);
      color: var(--color-neutral-surface);
    }

    &:hover,
    &:active,
    &:focus {
      &:not(:disabled),
      &:not(.disabled) {
        background-color: var(--color-brand-indigo);
        border-color: var(--color-brand-indigo);
      }
    }
  }

  &.secondary {
    &:not(:disabled),
    &:not(.disabled) {
      background-color: var(--color-neutral-surface);
      border-color: var(--color-brand-blue);
      color: var(--color-brand-blue);
    }

    &:hover,
    &:active,
    &:focus {
      &:not(:disabled),
      &:not(.disabled) {
        background: var(--color-brand-indigo);
        border-color: var(--color-brand-indigo);
        color: var(--color-neutral-surface);
      }
    }
  }

  &.tertiary {
    font-weight: 400;
    background-color: var(--color-neutral-surface);

    &:not(:disabled),
    &:not(.disabled) {
      color: var(--color-brand-blue);
    }

    &:hover,
    &:active,
    &:focus {
      &:not(:disabled),
      &:not(.disabled) {
        outline: 0;
        box-shadow: none;
        background-color: var(--color-brand-indigo);
        color: var(--color-neutral-surface);
      }
    }
  }

  &.indigo {
    &:not(:disabled),
    &:not(.disabled) {
      background-color: var(--color-brand-indigo);
      border-color: var(--color-brand-indigo);
      color: var(--color-neutral-surface);
    }

    &:hover,
    &:active,
    &:focus {
      &:not(:disabled),
      &:not(.disabled) {
        background-color: var(--color-brand-blue);
        border-color: var(--color-brand-blue);
      }
    }
  }

  &.indigo-reverse {
    &:not(:disabled),
    &:not(.disabled) {
      background-color: var(--color-brand-blue);
      border-color: var(--color-brand-blue);
      color: var(--color-neutral-surface);
    }

    &:hover,
    &:active,
    &:focus {
      &:not(:disabled),
      &:not(.disabled) {
        background-color: var(--color-brand-indigo);
        border-color: var(--color-brand-indigo);
      }
    }
  }

  &.danger {
    &:not(:disabled),
    &:not(.disabled) {
      border-color: var(--color-info-danger);
      color: var(--color-info-danger);
      background-color: var(--color-neutral-surface);
    }

    &:hover,
    &:active,
    &:focus {
      &:not(:disabled),
      &:not(.disabled) {
        border-color: var(--color-info-danger-text);
        background-color: var(--color-info-danger-text);
        color: var(--color-neutral-surface);
      }
    }
  }

  &.warning {
    &:not(:disabled),
    &:not(.disabled) {
      border-color: var(--color-info-warning-text);
      color: var(--color-info-warning-text);
      background-color: var(--color-neutral-surface);
    }

    &:hover,
    &:active,
    &:focus {
      &:not(:disabled),
      &:not(.disabled) {
        border-color: var(--color-info-warning-text);
        background-color: var(--color-info-warning-text);
        color: var(--color-neutral-surface);
      }
    }
  }

  &.tertiary--danger {
    &:not(:disabled),
    &:not(.disabled) {
      color: var(--color-info-danger);
    }

    &:hover,
    &:active,
    &:focus {
      &:not(:disabled),
      &:not(.disabled) {
        border-color: var(--color-info-danger-text);
        background-color: var(--color-info-danger-text);
        color: var(--color-neutral-surface);
      }
    }
  }

  &.sub-action--primary {
    padding: 0;
    min-height: fit-content;
    font-weight: 400;
    border: 0;
    border-radius: 0;

    .mq-button__child {
      padding: 0;
    }

    &:disabled,
    &.disabled {
      border: 0;
    }

    &:not(:disabled),
    &:not(.disabled) {
      color: var(--color-brand-indigo);
    }

    &:hover {
      &:not(:disabled),
      &:not(.disabled) {
        box-shadow: none;
        color: var(--color-brand-blue);
      }
    }

    &:focus,
    &:active {
      &:not(:disabled),
      &:not(.disabled) {
        box-shadow: none;
        outline: none;
        text-decoration: underline;
      }
    }
  }

  &.sub-action--danger {
    padding: 0;
    min-height: fit-content;
    font-weight: 400;
    border: 0;
    border-radius: 0;

    .mq-button__child {
      padding: 0;
    }

    &:disabled,
    &.disabled {
      border: 0;
    }

    &:not(:disabled),
    &:not(.disabled) {
      color: var(--color-info-danger);
    }

    &:hover {
      &:not(:disabled),
      &:not(.disabled) {
        box-shadow: none;
        color: var(--color-info-blue);
      }
    }

    &:focus,
    &:active {
      &:not(:disabled),
      &:not(.disabled) {
        box-shadow: none;
        outline: none;
        text-decoration: underline;
      }
    }
  }

  &.sub-action--tertiary {
    padding: 0;
    min-height: fit-content;
    font-weight: 400;
    border: 0;
    border-radius: 0;

    .mq-button__child {
      padding: 0;
    }

    &:disabled,
    &.disabled {
      border: 0;
    }

    &:not(:disabled),
    &:not(.disabled) {
      color: var(--color-brand-blue);
    }

    &:hover {
      &:not(:disabled),
      &:not(.disabled) {
        box-shadow: none;
        color: var(--color-brand-indigo);
      }
    }

    &:focus,
    &:active {
      &:not(:disabled),
      &:not(.disabled) {
        box-shadow: none;
        outline: none;
        text-decoration: underline;
      }
    }
  }

  &.sub-action--warning {
    padding: 0;
    font-weight: 400;
    min-height: fit-content;
    border: 0;
    border-radius: 0;

    .mq-button__child {
      padding: 0;
    }

    &:not(:disabled),
    &:not(.disabled) {
      color: var(--color-info-warning-text);
    }

    &:hover {
      &:not(:disabled),
      &:not(.disabled) {
        box-shadow: none;
        color: var(--color-info-warning-text);
      }
    }

    &:focus,
    &:hover,
    &:active {
      &:not(:disabled),
      &:not(.disabled) {
        box-shadow: none;
        outline: none;
        background: transparent;
      }
    }

    &.disabled,
    &:disabled {
      background: transparent;
    }
  }

  &.outline-primary {
    &:not(:disabled),
    &:not(.disabled) {
      color: var(--color-brand-indigo);
      border-color: var(--color-brand-indigo);
      background-color: var(--color-neutral-surface);

      &:hover,
      &:active,
      &:focus {
        color: var(--color-brand-indigo);
        border-color: var(--color-brand-indigo);
        background-color: var(--color-brand-indigo-light);
      }
    }
  }

  &.action {
    min-height: 0;
    min-width: 0;
    flex-shrink: 0;
    padding: 0;
    border: 0;
    line-height: inherit;
    flex-direction: column;
    gap: 0;
    transition: none;

    &:not(:disabled),
    &:not(.disabled) {
      color: var(--color-brand-indigo);
    }
  }

  &.icon {
    max-width: 100%;
    min-height: initial;
    max-height: initial;
    flex-shrink: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    font-weight: normal;
    font-size: var(--mq-font-size-scale-2);
    line-height: var(--mq-line-height-compact);
    flex-direction: column;
    gap: var(--gl-spacing-scale-3);
    transition: none;
    background: transparent;

    .mq-button__child {
      width: 100%;
    }

    &:not(:disabled),
    &:not(.disabled) {
      color: var(--color-brand-indigo);
    }

    .mq-button__start-icon,
    .mq-button__end-icon,
    .mq-button__secondary-icon,
    .mq-button__primary-icon {
      min-width: 2.5rem;
      border-radius: var(--mq-border-radius-xs);
      border: 1px solid var(--color-neutral-stroke);
      position: relative;
      padding: var(--gl-spacing-scale-3);
      font-size: var(--mq-font-size-scale-5);

      &:not(:disabled),
      &:not(.disabled) {
        background-color: var(--color-neutral-bkg);
      }

      .fa-primary {
        opacity: 1;
        fill: var(--color-brand-indigo);
      }

      .fa-secondary {
        opacity: 1;
        fill: var(--color-brand-blue);
      }
    }

    &:active,
    &:focus,
    &:hover {
      background: transparent;
      box-shadow: none;
      outline: none;

      &:not(:disabled),
      &:not(.disabled) {
        color: var(--color-brand-blue);

        .mq-button__start-icon,
        .mq-button__end-icon,
        .mq-button__secondary-icon,
        .mq-button__primary-icon {
          border: 1px solid var(--color-brand-blue);
          background-color: var(--color-brand-blue-light);
          color: var(--color-brand-blue);
        }
      }
    }

    &:disabled,
    &.disabled {
      opacity: 0.5;
      background: transparent;
    }
  }

  &.link {
    min-height: fit-content;
    flex-shrink: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    font-size: inherit;
    font-weight: 400;
    text-align: left;
    text-decoration: underline;

    &:not(:disabled),
    &:not(.disabled) {
      color: var(--color-brand-blue);
    }

    .mq-button__child {
      width: 100%;
      padding: 0;
    }

    &:active,
    &:focus,
    &:hover {
      &:not(:disabled),
      &:not(.disabled) {
        box-shadow: none;
        outline: none;
        text-decoration: underline;
        background: transparent;
        color: var(--color-brand-indigo);
      }
    }

    &:disabled,
    &.disabled {
      background-color: transparent;
    }
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    color: var(--color-info-disabled-text);
    background-color: var(--color-info-disabled);
    border-color: var(--color-info-disabled-stroke);
    box-shadow: none;
    outline: none;
  }

  &__spinner {
    padding: 0;
    width: max-content;
  }

  &.truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .mq-button__child {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .mq-button__start-icon,
  .mq-button__end-icon,
  .mq-button__secondary-icon,
  .mq-button__primary-icon {
    font-weight: 900;
  }

  .mq-button__start-icon:has(+ * + .mq-button__loading),
  .mq-button__secondary-icon:has(+ * + .mq-button__loading),
  .mq-button__primary-icon:has(+ * + .mq-button__loading),
  .mq-button__end-icon:has(+ .mq-button__loading) {
    opacity: 0;
  }
}