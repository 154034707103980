.mq-form-edit-area {
  width: 100%;
  min-height: 100px;
  border: 1px solid var(--color-neutral-stroke);
  border-radius: var(--mq-border-radius-sm);
  padding: 0 var(--gl-spacing-scale-3);

  &:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid var(--color-brand-indigo);
  }

  &.is-valid {
    border-color: var(--color-info-success);
  }

  &.is-invalid {
    border-color: var(--color-info-danger);
  }

  &.resize-none {
    resize: none;
  }

  &.resize-vertical {
    resize: vertical;
    overflow: auto;
  }

  &.resize-horizontal {
    resize: horizontal;
    overflow: auto;
  }

  .invalid-data {
    color: var(--color-info-danger);
  }
}