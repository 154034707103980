.mq-loader-thinking {
  display: flex;

  .mq-loader-thinking__text {
    min-height: 1.5rem;
    background: linear-gradient(90deg, var(--color-neutral-text-med), var(--color-neutral-text-pale), var(--color-neutral-text-med));
    background-size: 200% 100%;
    -webkit-background-clip: text;
    color: transparent;
    animation: loading 1.5s infinite ease-in-out;
  }

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
}