.chat-window {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 55px;
  right: 0;
  width: 800px;
  max-width: 60vw;
  height: 77vh;
  background-color: var(--color-neutral-bkg);
  border: 1px solid var(--color-neutral-stroke);
  border-radius: var(--mq-border-radius-md);
  box-shadow: var(--mq-shadow-lg);
  overflow-y: hidden;

  &.resized {
    width: 385px;
    max-width: 50vw;
    height: 65vh;
  }

  .chat-window__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--gl-spacing-scale-3) 0;
  }

  .chat-window__body {
    display: flex;
    flex-direction: column;
    gap: var(--gl-spacing-scale-3);
    padding: var(--gl-spacing-scale-3);
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 30px;
  }

  .chat-window__footer {
    border-radius: var(--mq-border-radius-md);
  }
}