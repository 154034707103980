.welcome-window {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 55px;
  right: 0;
  width: 385px;
  max-width: 50vw;
  height: 65vh;
  background-color: var(--color-neutral-bkg);
  border: 1px solid var(--color-neutral-stroke);
  border-radius: var(--mq-border-radius-md);
  box-shadow: var(--mq-shadow-lg);
  overflow-y: scroll;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;

  .welcome-window__header {
    display: flex;
    flex-direction: column;
    gap: var(--gl-spacing-scale-5);
    background-image: var(--gradient-brand-fresh);
    color: var(--color-neutral-surface);
    padding: var(--gl-spacing-scale-5);
  }

  .welcome-window__body {
    display: flex;
    flex-direction: column;
    margin: var(--gl-spacing-scale-4);
    gap: var(--gl-spacing-scale-4);

    .welcome-window__body-item {
      border: 1px solid var(--color-neutral-stroke);
      border-radius: var(--mq-border-radius-sm);
      color: inherit;
      text-decoration: none;

      .welcome-window__body-item__description {
        padding: var(--gl-spacing-scale-2);
      }
    }
  }
}